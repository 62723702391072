export const WITH_NAVIGATION_LAYOUT = "/_withNavigation";

export enum RoutePath {
  farm = "/",
  ai = "/ai",
  friends = "/friends",
  rewards = "/rewards",
  leaders = "/leaders",
  referral = "/referral",
  roadmap = "/roadmap",
  dailyBonus = "/dailyBonus",
  spins = "/spins"
}

export enum AIPath {
  "power" = `${RoutePath.ai}/`,
  "boost" = `${RoutePath.ai}/boost`
}
