/* eslint-disable */
// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Uuid: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type BoostTelegramChannel = {
  __typename?: 'BoostTelegramChannel';
  link: Scalars['String']['output'];
};

export type BuyPowerCardInput = {
  level: Scalars['Int']['input'];
  powerCardId: Scalars['Uuid']['input'];
};

export type Category = {
  __typename?: 'Category';
  cardsProposals: Array<PowerCardProposal>;
  createdAt: Scalars['Time']['output'];
  id: PowerCardCategory;
  maxCardLevel: Scalars['Int']['output'];
  minModelLevel: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ClaimFarmingReward = {
  __typename?: 'ClaimFarmingReward';
  current: Scalars['Int']['output'];
  target: Scalars['Int']['output'];
};

export type CompleteDailyTasks = {
  __typename?: 'CompleteDailyTasks';
  current: Scalars['Int']['output'];
  target: Scalars['Int']['output'];
};

export type DailyBonus = {
  __typename?: 'DailyBonus';
  amount: Scalars['Float']['output'];
  day: Scalars['Int']['output'];
  id: Scalars['Uuid']['output'];
  minReferees: Scalars['Int']['output'];
};

export type DailyStreak = {
  __typename?: 'DailyStreak';
  claimed: Scalars['Boolean']['output'];
  current: DailyBonus;
  id: Scalars['Uuid']['output'];
  next?: Maybe<NextDailyBonus>;
  previous?: Maybe<DailyBonus>;
  referees: Scalars['Int']['output'];
};

export type DailySubscribeToTelegram = {
  __typename?: 'DailySubscribeToTelegram';
  link: Scalars['String']['output'];
};

export type DailyTask = {
  __typename?: 'DailyTask';
  action: DailyTaskAction;
  actionText: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Uuid']['output'];
  points: Scalars['Float']['output'];
  progress: DailyTaskProgress;
  title: Scalars['String']['output'];
};

export type DailyTaskAction = ClaimFarmingReward | CompleteDailyTasks | DailySubscribeToTelegram | UpgradePowerCards;

export enum DailyTaskProgress {
  None = 'none',
  Pending = 'pending',
  RewardTook = 'rewardTook'
}

export type FarmingTask = {
  __typename?: 'FarmingTask';
  finishingAt: Scalars['Time']['output'];
  id: Scalars['Uuid']['output'];
  points: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
  serverTime: Scalars['Time']['output'];
  startedAt: Scalars['Time']['output'];
};

export type FreeSpin = {
  __typename?: 'FreeSpin';
  id: Scalars['Uuid']['output'];
  next: NextFreeSpin;
  sector: FreeSpinSector;
};

export type FreeSpinSector = {
  __typename?: 'FreeSpinSector';
  id: Scalars['Uuid']['output'];
  number: Scalars['Int']['output'];
  rewardAmount: Scalars['Float']['output'];
  rewardType: RewardType;
};

export type FreeSpinSectors = {
  __typename?: 'FreeSpinSectors';
  hash: Scalars['String']['output'];
  values: Array<FreeSpinSector>;
};

export type InviteFriends = {
  __typename?: 'InviteFriends';
  targetFriends: Scalars['Int']['output'];
};

export type LevelUp = {
  __typename?: 'LevelUp';
  targetLevel: Scalars['Int']['output'];
};

export type Me = {
  __typename?: 'Me';
  balance: Scalars['Float']['output'];
  currentFarmingTask?: Maybe<FarmingTask>;
  currentModel: ModelLevel;
  dailyStreak: DailyStreak;
  freeSpin?: Maybe<NextFreeSpin>;
  id: Scalars['Uuid']['output'];
  onboarded: Scalars['Boolean']['output'];
  power: Scalars['Int']['output'];
  profile: Profile;
  referralStats: ReferralStats;
  referrer: Referrer;
};

export type ModelLevel = {
  __typename?: 'ModelLevel';
  duration: Scalars['Int']['output'];
  id: Scalars['Uuid']['output'];
  minPower: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Increses farm rate. Applies on next farm.
   *
   * Errors:
   * - ModelLevelNotFound - if model with such level not found
   * - OutdatedModelPrice - if model price and saw price differs
   * - YouAlreadyHaveBetterModel - if current user model level is higher than
   * - YouCanOnlyUpdateToNextLevelModel - if difference between current and desired level > 1
   * - InsufficientFunds - if user doesn`t have enough money for upgrade
   */
  boostModel: ModelLevel;
  buyPowerCard: PowerCardProposal;
  changeNickname: Profile;
  /** Claims daily bonus and updates progress */
  claimDailyReward: DailyStreak;
  /**
   * Finishes farming task and collect rewards by sending points to user balance
   *
   * Errors:
   * - NoRewardReadyToCollect - if there no farming task with finishing_at < now
   */
  claimFarmingReward: FarmingTask;
  /**
   * Claims referee reward and sends referrer reward to user who invited referee
   * Only works if referee status = rewardReady
   * So, this mutation must called only after useReferralToken
   * Also, user must be onboarded and his first day in daily streak claimed
   *
   * Second call will just return state from db
   *
   * Errors:
   * - NoRewardReady - if no referrer found or status not rewardReady
   */
  claimRefereeReward: Referrer;
  /** Creates or finds token for referral link to share with friends */
  createOrGetReferralToken: Scalars['String']['output'];
  finishOnboarding: Me;
  /**
   * Logs in the user via telegram init data
   * and stores auth info to session cookie
   *
   * Errors:
   * - InvalidInitData - if the init data is invalid or expired
   */
  loginByTelegramInitData: Scalars['Void']['output'];
  /**
   * Performs free spin
   *
   * Errors:
   * - HashMismatch - if hash of sectors changed. Please query fresh freeSpinSectors
   */
  performFreeSpin: FreeSpin;
  /**
   * Errors:
   * - DailyTaskNotFound - if there no daily task with such id
   */
  startDailyTaskProgress: DailyTask;
  /** Finds active farming task and if there no such task - starts new */
  startFarming: FarmingTask;
  /**
   * Errors:
   * - TaskNotFound - if there no task with such id
   */
  startTaskProgress: Task;
  /**
   * Validates and if user isn`t attached to any referrer - attaches his with rewardReady status to user who
   * issued this token
   *
   * Errors:
   * - InvalidToken - if the token is invalid or not found in session
   */
  useReferralToken: Referrer;
  /**
   * Verifies and claims reward for completing daily task
   *
   * Errors:
   * - DailyTaskNotFound - if there no daily task with such id
   */
  verifyDailyTaskProgress: DailyTask;
  /**
   * Verifies and claims reward for completing task
   *
   * Errors:
   * - TaskNotFound - if there no task with such id
   */
  verifyTaskProgress: Task;
};


export type MutationBoostModelArgs = {
  level: Scalars['Int']['input'];
  sawPrice: Scalars['Float']['input'];
};


export type MutationBuyPowerCardArgs = {
  request: BuyPowerCardInput;
};


export type MutationChangeNicknameArgs = {
  nickname: Scalars['String']['input'];
};


export type MutationFinishOnboardingArgs = {
  sendWelcomeMessage?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationLoginByTelegramInitDataArgs = {
  initData: Scalars['String']['input'];
};


export type MutationPerformFreeSpinArgs = {
  hash: Scalars['String']['input'];
};


export type MutationStartDailyTaskProgressArgs = {
  id: Scalars['Uuid']['input'];
};


export type MutationStartTaskProgressArgs = {
  id: Scalars['Uuid']['input'];
};


export type MutationUseReferralTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationVerifyDailyTaskProgressArgs = {
  id: Scalars['Uuid']['input'];
};


export type MutationVerifyTaskProgressArgs = {
  id: Scalars['Uuid']['input'];
};

export type NextDailyBonus = {
  __typename?: 'NextDailyBonus';
  availableAt: Scalars['Time']['output'];
  serverTime: Scalars['Time']['output'];
};

export type NextFreeSpin = {
  __typename?: 'NextFreeSpin';
  availableAt: Scalars['Time']['output'];
  serverTime: Scalars['Time']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type PowerCard = {
  __typename?: 'PowerCard';
  category: PowerCardCategory;
  description: Scalars['String']['output'];
  id: Scalars['Uuid']['output'];
  imageUrl: Scalars['String']['output'];
  maxLevel: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export enum PowerCardCategory {
  Data = 'data',
  Hardware = 'hardware',
  Partnership = 'partnership',
  Team = 'team'
}

export type PowerCardProposal = {
  __typename?: 'PowerCardProposal';
  id: Scalars['Uuid']['output'];
  powerCard?: Maybe<PowerCard>;
  sellingLevel: Scalars['Int']['output'];
  sellingPowerBoost: Scalars['Int']['output'];
  sellingPrice: Scalars['Int']['output'];
  userLevel: Scalars['Int']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['Uuid']['output'];
  level: Scalars['Int']['output'];
  nickname: Scalars['String']['output'];
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  cursor: Scalars['String']['output'];
  node: Profile;
};

export type Query = {
  __typename?: 'Query';
  dailyBonuses: Array<DailyBonus>;
  dailyTasks: Array<DailyTask>;
  freeSpinSectors: FreeSpinSectors;
  me: Me;
  modelLevels: Array<ModelLevel>;
  powerCardsCategories: Array<Category>;
  referees: Referees;
  referralRewards: ReferralRewards;
  rewardsOnRefereeLevelUp: Array<RewardOnRefereeLevelUp>;
  /**
   * Errors:
   * - TaskNotFound - if there no task with such id
   */
  task: Task;
  tasks: Tasks;
};


export type QueryRefereesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: Scalars['Int']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['Uuid']['input'];
};

export type Referee = {
  __typename?: 'Referee';
  id: Scalars['Uuid']['output'];
  invitedAt: Scalars['Time']['output'];
  points: Scalars['Float']['output'];
  profile?: Maybe<Profile>;
};

export type RefereeEdge = {
  __typename?: 'RefereeEdge';
  cursor: Scalars['String']['output'];
  node: Referee;
};

export enum RefereeStatus {
  Noop = 'noop',
  RewardReady = 'rewardReady',
  RewardTook = 'rewardTook'
}

export type Referees = {
  __typename?: 'Referees';
  edges: Array<RefereeEdge>;
  pageInfo: PageInfo;
};

export type ReferralRewards = {
  __typename?: 'ReferralRewards';
  invitePremiumFriend: Scalars['Float']['output'];
  inviteRegularFriend: Scalars['Float']['output'];
};

export type ReferralStats = {
  __typename?: 'ReferralStats';
  referees: Scalars['Int']['output'];
  rewards: Scalars['Float']['output'];
};

export type Referrer = {
  __typename?: 'Referrer';
  id: Scalars['Uuid']['output'];
  profile?: Maybe<Profile>;
  refereePoints: Scalars['Float']['output'];
  refereeStatus: RefereeStatus;
};

export type RewardOnRefereeLevelUp = {
  __typename?: 'RewardOnRefereeLevelUp';
  amount: Scalars['Float']['output'];
  id: Scalars['Uuid']['output'];
  level: ModelLevel;
};

export enum RewardType {
  Coin = 'coin',
  Usdt = 'usdt'
}

export type SubscribeToInstagram = {
  __typename?: 'SubscribeToInstagram';
  link: Scalars['String']['output'];
};

export type SubscribeToTelegram = {
  __typename?: 'SubscribeToTelegram';
  link: Scalars['String']['output'];
};

export type SubscribeToX = {
  __typename?: 'SubscribeToX';
  link: Scalars['String']['output'];
};

export type SubscribeToYoutube = {
  __typename?: 'SubscribeToYoutube';
  link: Scalars['String']['output'];
};

export type Task = {
  __typename?: 'Task';
  action: TaskAction;
  actionText: Scalars['String']['output'];
  complexity: TaskComplexity;
  description: Scalars['String']['output'];
  id: Scalars['Uuid']['output'];
  points: Scalars['Float']['output'];
  progress: TaskProgress;
  title: Scalars['String']['output'];
};

export type TaskAction = BoostTelegramChannel | InviteFriends | LevelUp | SubscribeToInstagram | SubscribeToTelegram | SubscribeToX | SubscribeToYoutube | WatchYoutubeVideo;

export enum TaskComplexity {
  Easy = 'easy'
}

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['String']['output'];
  node: Task;
};

export enum TaskProgress {
  None = 'none',
  Pending = 'pending',
  RewardTook = 'rewardTook'
}

export type Tasks = {
  __typename?: 'Tasks';
  edges: Array<TaskEdge>;
  pageInfo: PageInfo;
};

export type UpgradePowerCards = {
  __typename?: 'UpgradePowerCards';
  current: Scalars['Int']['output'];
  target: Scalars['Int']['output'];
};

/** The power score of each user who has a power score. */
export type UserPower = {
  __typename?: 'UserPower';
  /** The user id. */
  id: Scalars['Uuid']['output'];
  powerScore: Scalars['Int']['output'];
};

export type UserPowerCard = {
  __typename?: 'UserPowerCard';
  cumulativeCost: Scalars['Int']['output'];
  cumulativePowerBoost: Scalars['Int']['output'];
  id: Scalars['Uuid']['output'];
  level: Scalars['Int']['output'];
  levelCost: Scalars['Int']['output'];
  levelPowerBoost: Scalars['Int']['output'];
};

export type UserPowerCardHistory = {
  __typename?: 'UserPowerCardHistory';
  id: Scalars['Uuid']['output'];
  powerCardId: Scalars['Uuid']['output'];
  powerCardLevel: Scalars['Int']['output'];
  purchasedAt: Scalars['Time']['output'];
  userId: Scalars['Uuid']['output'];
};

export type VoidBox = {
  __typename?: 'VoidBox';
  value: Scalars['Void']['output'];
};

export type WatchYoutubeVideo = {
  __typename?: 'WatchYoutubeVideo';
  link: Scalars['String']['output'];
};
