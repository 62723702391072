/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { DTOCurrentFarmingTaskFragment } from '../../../components/farming/useFarming/graphql/currentFarmingTaskFragment.generated';
import { DTOCurrentModelFragment } from './meCurrentModelFragment.generated';
import { DTODailyStreakFragment } from './dailyStreakFragment.generated';
import { gql } from '@urql/core';
import { CurrentFarmingTaskFragmentDoc } from '../../../components/farming/useFarming/graphql/currentFarmingTaskFragment.generated';
import { CurrentModelFragmentDoc } from './meCurrentModelFragment.generated';
import { DailyStreakFragmentDoc } from './dailyStreakFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOMeQuery = { __typename?: 'Query', me: { __typename?: 'Me', id: any, balance: number, onboarded: boolean, profile: { __typename?: 'Profile', id: any, nickname: string }, currentFarmingTask?: (
      { __typename?: 'FarmingTask' }
      & DTOCurrentFarmingTaskFragment
    ) | null, currentModel: (
      { __typename?: 'ModelLevel' }
      & DTOCurrentModelFragment
    ), referrer: { __typename?: 'Referrer', refereeStatus: Types.DTORefereeStatus, refereePoints: number }, referralStats: { __typename?: 'ReferralStats', referees: number, rewards: number }, dailyStreak: (
      { __typename?: 'DailyStreak' }
      & DTODailyStreakFragment
    ) } };


export const MeQueryDocument = gql`
    query MeQuery {
  me {
    id
    profile {
      id
      nickname
    }
    balance
    onboarded
    currentFarmingTask {
      ...CurrentFarmingTaskFragment
    }
    currentModel {
      ...CurrentModelFragment
    }
    referrer {
      refereeStatus
      refereePoints
    }
    referralStats {
      referees
      rewards
    }
    dailyStreak {
      ...DailyStreakFragment
    }
  }
}
    ${CurrentFarmingTaskFragmentDoc}
${CurrentModelFragmentDoc}
${DailyStreakFragmentDoc}`;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<DTOMeQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOMeQuery, DTOMeQueryVariables>({ query: MeQueryDocument, ...options });
};