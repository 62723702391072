import "./App.css";
import { FC, useCallback, useEffect, useState } from "react";
import { InitialAppLoader } from "~/components/ui/loaders/InitialAppLoader/InitialAppLoader";
import { useReady } from "~/utils/telegram/useReady";
import { wait } from "~/utils/async";
import { MILLISECONDS_IN_SECOND } from "~/constants/time";
import {
  DTOMeQuery,
  MeQueryDocument
} from "~/graphql/useMe/graphql/meQuery.generated";
import { TasksQueryDocument } from "~/components/rewards/Rewards/Tasks/useTasks/graphql/tasksQuery.generated";
import { DailyBonusesQueryDocument } from "~/components/dailyBonus/DailyBonuses/useDailyBonuses/graphql/dialyBonusesQuery.generated";
import { useAppAvailableForPlatform } from "~/utils/telegram/useAppAvailableForPlatform";
import { QRCodeWithLinkToBot } from "~/components/onboarding/QRCodeWithLinkToBot/QRCodeWithLinkToBot";
import { ReferralRewardsQueryDocument } from "./components/friends/graphql/referralRewards.generated";
import { RefereesQueryDocument } from "./components/friends/graphql/refereesQuery.generated";
import { REFEREES_PER_BATCH } from "./components/friends/Friends/Friends";
import { FARMING_ANIMATION_PAYLOAD } from "~/components/common/SpinePlayer/ImmortalSpinePlayer/constants";
import { useLoginMutation } from "~/graphql/useLogin/graphql/loginMutation.generated";
import { retrieveLaunchParams } from "./utils/telegram/retrieveLaunchParams";
import { OperationResult, useClient } from "urql";
import { isNotNullable } from "./utils/common";
import { ErrorPlaceholder } from "./components/errors/ErrorPlaceholder/ErrorPlaceholder";
import { useUseReferralTokenMutation } from "./graphql/useReferralTokenMutation.generated";
import { RefereeStatus } from "./declarations/graphql/types";
import { useInitData } from "@telegram-apps/sdk-react";
import { RouletteQueryDocument } from "./components/spins/Spins/graphql/rouletteQuery.generated";
import { PowerCardCategoriesQueryDocument } from "./components/ai/power/graphql/powerCardCategoriesQuery.generated";
import { DailyTasksQueryDocument } from "./components/rewards/Rewards/Tasks/DailyTasksGroup/useDialyTasks/graphql/dailyTasksQuery.generated";

const TIMEOUT_JUST_FOR_IMPRESSION: Milliseconds = 2 * MILLISECONDS_IN_SECOND;
const TIMEOUT_TO_SHOW_FILLED_LOADER: Milliseconds = 300;

function App() {
  useReady();

  const { initDataRaw } = retrieveLaunchParams();
  const [_loginResult, login] = useLoginMutation();
  const initData = useInitData();
  const [, referralTokenMutation] = useUseReferralTokenMutation();
  const urqlClient = useClient();

  const isAppAvailableForPlatform = useAppAvailableForPlatform();

  const [AppContent, setAppContent] = useState<FC | null>(null);
  const [isStartedLoading, setIsStartedLoading] = useState(false);
  const [isLoaderShown, setIsLoaderShown] = useState(true);
  const [isLoadingDone, setIsLoadingDone] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleTryAgain = useCallback(() => {
    window.location.reload();
  }, []);

  const sendReferralToken = useCallback(
    async (refereeStatus?: RefereeStatus) => {
      const isNoopStatus = refereeStatus === RefereeStatus.Noop;
      const startParam = initData?.startParam;

      if (isNoopStatus && startParam) {
        referralTokenMutation({ token: startParam });
      }
    },
    [initData?.startParam, referralTokenMutation]
  );

  useEffect(() => {
    if (!isAppAvailableForPlatform || isStartedLoading) {
      return;
    }

    setIsStartedLoading(true);
    const loadAppContent = async () => {
      try {
        const module = await import("~/AppContent");
        const AppContent = module.default;

        setAppContent(() => AppContent);

        return {};
      } catch (error) {
        return {
          error
        };
      }
    };

    const loadResources = async () => {
      try {
        const loginResponse = await login({
          initData: initDataRaw
        });

        if (loginResponse.error) {
          throw new Error("Can't login");
        }

        const queriesToPrefetch = [
          urqlClient.query(MeQueryDocument, {}).toPromise(),
          urqlClient.query(TasksQueryDocument, {}).toPromise(),
          urqlClient.query(DailyBonusesQueryDocument, {}).toPromise(),
          urqlClient.query(ReferralRewardsQueryDocument, {}).toPromise(),
          urqlClient.query(PowerCardCategoriesQueryDocument, {}).toPromise(),
          urqlClient
            .query(RefereesQueryDocument, {
              first: REFEREES_PER_BATCH
            })
            .toPromise(),
          urqlClient.query(RouletteQueryDocument, {}).toPromise(),
          urqlClient.query(DailyTasksQueryDocument, {}).toPromise()
        ];

        const queryResults = await Promise.all([
          ...queriesToPrefetch,
          FARMING_ANIMATION_PAYLOAD,
          loadAppContent(),
          wait(TIMEOUT_JUST_FOR_IMPRESSION)
        ]);

        const hasError = queryResults.some(
          result =>
            result &&
            typeof result === "object" &&
            "error" in result &&
            isNotNullable(result.error)
        );

        if (hasError) {
          throw new Error("Error during prefetching queries");
        }

        const meResult = queryResults[0] as OperationResult<DTOMeQuery>;
        sendReferralToken(meResult.data?.me.referrer.refereeStatus);

        setIsLoadingDone(true);
        await wait(TIMEOUT_TO_SHOW_FILLED_LOADER);
        setIsLoaderShown(false);
      } catch {
        setHasError(true);
        setIsLoadingDone(true);
        setIsLoaderShown(false);
      }
    };

    loadResources();
  }, [
    login,
    isAppAvailableForPlatform,
    initDataRaw,
    urqlClient,
    referralTokenMutation,
    sendReferralToken,
    isStartedLoading
  ]);

  if (hasError) {
    return <ErrorPlaceholder onTryAgain={handleTryAgain} />;
  }

  if (!isAppAvailableForPlatform) {
    return <QRCodeWithLinkToBot />;
  }

  if (isLoaderShown) {
    return (
      <InitialAppLoader isDone={isLoadingDone} className="max-w-[744px]" />
    );
  }

  if (AppContent) {
    return <AppContent />;
  }

  return null;
}

export default App;
