import { useTranslation } from "react-i18next";
import Button from "~/components/ui/button";
import {
  DrawerBody,
  DrawerBodyImg,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from "~/components/ui/drawer";
import Close from "~/assets/icons/Close.svg?react";
import HowItWorksLogo from "~/components/friends/assets/HowItWorksLogo.png";
import FriendsHeaderHowItWorksStatement from "./FriendsHeaderHowItWorksStatement";
import { FriendBonuses } from "~/components/friends/FriendsHeader/types";

type Props = {
  bonuses: FriendBonuses;
};

const FriendsHeaderHowItWorks = ({ bonuses }: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <DrawerContent>
      <DrawerHeader>
        <DrawerTitle>{t("howItWorks.title")}</DrawerTitle>
        <DrawerClose>
          <Close className="w-6 h-6 text-background-quaternary hover:text-background-tertiary" />
        </DrawerClose>
      </DrawerHeader>
      <DrawerBody className="flex flex-col items-center gap-5">
        <DrawerBodyImg src={HowItWorksLogo} />
        <FriendsHeaderHowItWorksStatement
          translationKey="howItWorks.invite"
          translationValues={bonuses}
        />
        <FriendsHeaderHowItWorksStatement
          translationKey="howItWorks.register"
          translationValues={bonuses}
        />
        <FriendsHeaderHowItWorksStatement
          translationKey="howItWorks.earn"
          translationValues={bonuses}
        />
        <FriendsHeaderHowItWorksStatement
          translationKey="howItWorks.repeat"
          translationValues={bonuses}
        />
      </DrawerBody>
      <DrawerFooter>
        <DrawerClose>
          <Button fullWidth>{t("actions.gotIt", { ns: "common" })}</Button>
        </DrawerClose>
      </DrawerFooter>
    </DrawerContent>
  );
};

export default FriendsHeaderHowItWorks;
