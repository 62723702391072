import { ReactNode } from "react";
import BackgroundGrid from "./assets/BackgroundGrid.png";
import BackgroundTopLeftSpot from "./assets/BackgroundTopLeftSpot.svg?react";
import BackgroundBottomRightSpot from "./assets/BackgroundBottomRightSpot.svg?react";
import { cn } from "~/utils";

type Props = {
  children: ReactNode;
  wrapperClassName?: string;
  className?: string;
};

const PageWithBackground = ({
  children,
  className,
  wrapperClassName
}: Props): JSX.Element => {
  return (
    <div
      className={cn(
        "h-full w-full top-0 bg-no-repeat bg-[length:auto_100%] overflow-hidden bg-fixed bg-[center_top]",
        wrapperClassName
      )}
      style={{ backgroundImage: `url(${BackgroundGrid})` }}
    >
      <BackgroundTopLeftSpot
        className={`
          absolute w-[304px] h-[304px] top-[3px] left-[-64px] 
        `}
      />
      <BackgroundBottomRightSpot
        className={`
          absolute w-[304px] h-[304px] bottom-[-23px] right-[-83px] 
        `}
      />
      <div
        className={cn(
          `relative w-full h-full overflow-y-auto 
           scrollbar-gutter-stable scrollbar-gutter-both-edges`,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageWithBackground;
