import { useBonusesForFriendsQuery } from "./graphql/bonusesForFriendsQuery.generated";
import { FriendsLevelBonusByLevel } from "./types";

type Result = {
  bonuses: FriendsLevelBonusByLevel[];
  maxBonus: FriendsLevelBonusByLevel | null;
};

export const useBonusesForFriendsByLevel = (): Result => {
  const [{ data: bonusesForFriends }] = useBonusesForFriendsQuery();
  const bonuses: FriendsLevelBonusByLevel[] =
    bonusesForFriends?.rewardsOnRefereeLevelUp
      ?.map<FriendsLevelBonusByLevel>(bonus => ({
        level: bonus.level.number,
        name: bonus.level.name,
        count: bonus.amount
      }))
      .sort((a, b) => a.level - b.level) ?? [];

  const maxBonus = bonuses[bonuses.length - 1] ?? null;

  return {
    bonuses,
    maxBonus
  };
};
