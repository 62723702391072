import { useViewport, useSwipeBehavior } from "@telegram-apps/sdk-react";
import { useState } from "react";

export const useExpandAndLock = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const viewport = useViewport();
  const swipeBehavior = useSwipeBehavior();

  if (!isInitialized && viewport && !viewport.isExpanded) {
    try {
      viewport.expand();

      if (swipeBehavior.supports("disableVerticalSwipe")) {
        swipeBehavior.disableVerticalSwipe();
      }
    } finally {
      setIsInitialized(true);
    }
  }
};
