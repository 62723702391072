import { startFarming } from "./startFarming";
import { claimFarmingReward } from "./claimFarmingReward";
import { boostModel } from "./boostModel";
import { claimRefereeReward } from "./claimRefereeReward";

const mutationsIndex = {
  claimFarmingReward,
  startFarming,
  boostModel,
  claimRefereeReward
};

export default mutationsIndex;
