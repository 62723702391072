import { initMiniApp, initBackButton } from "@telegram-apps/sdk";
import { useEffect } from "react";

const [miniApp] = initMiniApp();
const [backButton] = initBackButton();

export const useReady = () => {
  useEffect(() => {
    try {
      miniApp.ready();
    } finally {
      if (backButton.supports("hide")) {
        backButton.hide();
      }
    }
  }, []);
};
